import { StaticQuery, graphql } from "gatsby";
import * as styles from "./style.module.scss";
import React from "react";
import Animate from "../IntersectionAnimator/Animate";
export default function School() {
    return (
        <div id="education" className={styles.school}>
            <h2>
                Education <span>📓</span>
            </h2>
            <StaticQuery
                query={graphql`
                    query {
                        allEducationJson {
                            nodes {
                                degree
                                date
                                school
                                logo {
                                    childSvg {
                                        content {
                                            data
                                            width
                                            height
                                        }
                                    }
                                }
                            }
                        }
                    }
                `}
                render={(data) =>
                    data.allEducationJson.nodes.map((job, index) => (
                        <Animate key={job.degree} direction={"up"}>
                            <div key={job.degree}>
                                <div
                                    className={`${styles.logoContainer}`}
                                    dangerouslySetInnerHTML={{
                                        __html: job.logo?.childSvg?.content
                                            .data,
                                    }}
                                ></div>
                                <h5>{job.degree}</h5>
                                <h6>{job.date}</h6>
                            </div>
                        </Animate>
                    ))
                }
            />
        </div>
    );
}
