import { StaticQuery, graphql } from "gatsby";

import * as styles from "./style.module.scss";
import React from "react";

import Animate from "../IntersectionAnimator/Animate";

export default function Work() {
    return (
      
            <div id="work" className={styles.work}>
            <   h2>Work Experience 👔</h2>
                <StaticQuery
                    query={graphql`
                        query {
                            allJobsJson {
                                nodes {
                                    points {
                                        point
                                        sub_points
                                    }
                                    logo {
                                        childSvg {
                                            content {
                                                data
                                                width
                                                height
                                            }
                                        }
                                    }
                                    tech
                                    title
                                    company
                                    date
                                }
                            }
                        }
                    `}
                    render={(data) =>
                        data.allJobsJson.nodes.map((job) => (
                            <Animate key={job.title} direction={"up"}>
                            <div key={job.title} className={styles.job}>
                                <div
                                    className={`${styles.logoContainer}
                                     ${
                                         job.company === "RBC Wealth Management"
                                             ? styles.rbc
                                             : ""
                                     } 
                                    ${
                                        job.company ===
                                        "Ontario Ministry of Education"
                                            ? styles.ministry
                                            : ""
                                    }
                                  `}
                                    dangerouslySetInnerHTML={{
                                        __html: job.logo?.childSvg?.content
                                            .data,
                                    }}
                                ></div>
                                {/* <h4>{job.company}</h4> */}
                                <span>
                                <h5>{job.title}</h5>
                                <h6>{job.date}</h6>
                                </span>
                                <ul>
                                    {job.tech.map((tech) => (
                                        <li key={tech}>{tech}</li>
                                    ))}
                                </ul>
                            </div>
                            </Animate>
                        ))
                    }
                />
            </div>
      
    );
}
