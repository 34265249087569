import * as React from "react";

import { Link } from "gatsby";
import * as styles from "./style.module.scss";
import Animate from "../IntersectionAnimator/Animate";
// import ParallaxContext from "../../contexts/Parallax/ParallaxContext";

const Top = () => {
    // const { offsetY } = React.useContext(ParallaxContext);

    // let parallaxUp = {transform: `translate3d(0px, -${Math.min(offsetY * 0.2, 400)}px, 0px)`}
    // let parallaxLeft = {transform: `translate3d(-${Math.min(offsetY * 0.2, 400)}px,0px, 0px)`}
    // let parallaxRight = {transform: `translate3d(${Math.min(offsetY * 0.2, 400)}px,0px, 0px)`}
    // let parallaxDown = {transform: `translate3d(0px, ${Math.min(offsetY * 0.2, 400)}px, 0px)`}
    return (
        <div className={styles.topSection}>
            <Animate>
                <span>
                    <h2>Hello! 👋🏼</h2>

                    <h1> I'm Sagar Suri</h1>
                </span>
            </Animate>
            <Animate stagger={2}>
                <h4>
                    I am a <strong>software engineer</strong> based in the
                    Greater Toronto Area focused on building everything from
                    mobile applications to responsive web experiences.
                </h4>
            </Animate>
            <Animate stagger={3}>
                <Link to="#projects">
                    {" "}
                    <h2>⬇️ </h2>
                </Link>
            </Animate>
        </div>
    );
};

export default Top;
