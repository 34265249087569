import React from "react";
import Top from "../components/Top/Top";
import Nav from "../components/Nav/Nav";
import Projects from "../components/Projects/Projects";
import Work from "../components/Work/Work"
import School from "../components/School/School"
import Footer from "../components/Footer/Footer";
import Seo from "../components/SEO/SEO";

import {ThemeProvider} from '../contexts/Theme/ThemeProvider'

// markup
const IndexPage = () => {

    return (

        <ThemeProvider>
            <Seo title="Sagar Suri Portfolio"/>
            <Nav/>

            <main>


                <Top className="section"/>
                <Projects className="section"/>
                <Work className="section"/>
                <School className="section"/>
                <Footer/>
            </main>


        </ThemeProvider>
    );
};

export default IndexPage;
