import { StaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

// import "swiper/swiper.scss";
import * as styles from "./style.module.scss";
import React from "react";
import Animate from "../IntersectionAnimator/Animate";

export default function Projects2() {
    return (
        <div id="projects" className={styles.projects}>
            <h2>Some things I've worked on 🧑🏻‍💻</h2>
            <StaticQuery
                query={graphql`
                    query {
                        allProjectsJson {
                            nodes {
                                title
                                tech
                                color
                                logo {
                                    childSvg {
                                        content {
                                            data
                                            width
                                            height
                                        }
                                    }
                                }
                                description
                                images {
                                    childImageSharp {
                                        gatsbyImageData
                                    }
                                }
                            }
                        }
                    }
                `}
                render={(data) =>
                    data.allProjectsJson.nodes.map((project, index) => (
                        <div
                            key={project.title}
                            className={`${styles.project} ${
                                index % 2 ? styles.odd : styles.even
                            }`}
                            // style={{backgroundColor: adjust(project.color, 150)}}
                        >
                            <Animate  key={project.title} direction={index % 2 ? "left" : "right"}>
                                <div
                                key={project.title}
                                    className={
                                        (project.images.length === 1 &&
                                            styles.singleImage) ||
                                        (project.images.length === 2 &&
                                            styles.doubleImage) ||
                                        (project.images.length === 3 &&
                                            styles.tripleImage) ||
                                        (project.images.length === 4 &&
                                            styles.quadImage)
                                    }
                                >
                                    {project.images.map((image, index) => (
                                        <GatsbyImage
                                            key={project.title + " " + index}
                                            // objectFit="contain"
                                            className={
                                                styles.projectImageWrapper
                                            }
                                            imgClassName={styles.projectImage}
                                            image={getImage(image)}
                                            alt={project.title + " " + index}
                                        />
                                    ))}
                                </div>
                            </Animate>

                            <div className={styles.projectContent}>
                                <h3>{project.title}</h3>
                                <Animate
                                    direction={index % 2 ? "right" : "left"}
                                >
                                    <div className={styles.projectDescription}>
                                        <p>{project.description}</p>
                                    </div>
                                </Animate>

                                <div className={styles.techList}>
                                    {project.tech.map((tech, index) => (
                                        <Animate stagger={index}>
                                            <li key={tech}>{tech}</li>
                                        </Animate>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))
                }
            />
            <div className={styles.projectArchiveBox}>
                <h3>Want to see more? </h3>
                <h4 className={styles.viewArchive}>
                    <Link to="/archive">View Project Archive</Link>
                </h4>
            </div>
        </div>
    );
}
