// extracted by mini-css-extract-plugin
export var projects = "style-module--projects--K2tlY";
export var project = "style-module--project--2jXOB";
export var projectContent = "style-module--projectContent--2Kgkk";
export var projectDescription = "style-module--projectDescription--1DmqE";
export var techList = "style-module--techList--1CHem";
export var odd = "style-module--odd--1VNnW";
export var even = "style-module--even--2W1Wr";
export var singleImage = "style-module--singleImage--uKLh4";
export var doubleImage = "style-module--doubleImage--2Rx-i";
export var tripleImage = "style-module--tripleImage--3QyCx";
export var quadImage = "style-module--quadImage--2pEkW";
export var projectImageWrapper = "style-module--projectImageWrapper--1PKcx";
export var projectImage = "style-module--projectImage--vMke3";
export var projectArchiveBox = "style-module--projectArchiveBox--2J95k";
export var viewArchive = "style-module--viewArchive--2FBqZ";